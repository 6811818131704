<template>
  <v-form ref="form">
    <default-form-layout>
      <template v-slot:title>
        <h2>Alterar agregado</h2>
      </template>
      <template v-slot:body>
        <v-progress-linear v-show="loading" indeterminate />
        <v-row>
          <v-col xs="12" md="4" class="pa-3">
            <v-autocomplete
              :cache-items="true"
              chips
              :deletable-chips="true"
              class="required"
              v-model="register.name"
              :items="suppliers"
              item-value="name"
              item-text="name"
              type="text"
              label="Agregado"
            ></v-autocomplete>
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-autocomplete
              :cache-items="true"
              :multiple="true"
              chips
              class="required"
              hide-no-data
              item-text="name"
              item-value="id"
              label="CPF"
            >
            </v-autocomplete>
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-select
              class="required"
              clearable
              color="primary"
              chips
              deletable-chips
              :items="types"
              v-model="register.type"
              item-value="id"
              item-text="name"
              label="Grau"
              :rules="[formRules.required]"
            ></v-select>
          </v-col>

          <!-- <v-col xs="12" md="4" class="pa-3">
            <v-select
              class="required"
              clearable
              color="primary"
              multiple
              chips
              deletable-chips
              :items="systems"
              v-model="register.systems"
              item-value="id"
              item-text="name"
              label="Uso"
              :rules="[formRules.required]"
            ></v-select>
          </v-col> -->
<!-- 
          <v-col xs="12" md="4" class="pa-3">
            <v-switch
              v-model="register.active"
              color="#b9041c"
              :label="register.active ? 'Ativo' : 'Inativo'"
            ></v-switch>
          </v-col> -->
        </v-row>
      </template>

      <template v-slot:actions>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn color="error" text :to="`/suppliers/edit/${id}`">Cancelar</v-btn>
      </template>
    </default-form-layout>
  </v-form>
</template>

<script>
import DefaultFormLayout from "@/shared/components/layout/DefaultFormLayout.vue";

export default {
  components: { DefaultFormLayout },
  name: "RegisterAggregate",
  data() {
    return {
      id: null,
      headerText: "Novo",
      formRules: { required: (v) => !!v || "Campo obrigatório!" },
      register: {
        aggregates: [],
        systems: [],
        active: 1,
      },
      loading: false,
      suppliers: [],
      systems: [],
      types: ["Sociedade", "Familiar"],

      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },
  async mounted() {
    try {
      this.loading = true;

      await this.getSuppliers();
      await this.getSystems();

      this.id = this.$route.params.id;
      if (this.id) {
        this.headerText = "Alterar ";
        await this.getData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async getSuppliers() {
      const response = await this.minamelServices
        .SupplierServiceV2()
        .getAll({});

        console.log('response: ', response);

      this.suppliers = response.data;
    },

    async getSystems() {
      const response = await this.minamelServices.SystemServiceV2().getAll();

      this.systems = response.data;
    },

    async getData() {
      this.loading = true;

      const response = await this.minamelServices
        .SupplierServiceV2()
        .show(this.id);

      this.register = response.data;
      this.register.aggregates = this.register.aggregates.map(
        (item) => item.id
      );

      this.register.systems = this.register.systems.map((item) => item.id);
    },

    async save() {
      const formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      if (this.id) {
        this.update();
        return;
      }

      this.create();
    },

    async create() {
      try {
        const response = await this.minamelServices
          .SupplierServiceV2()
          .createAggregate(this.register);

        if (response.message) {
          this.$swal.fire({
            title: "Atenção",
            text: response.message,
            type: "info",
            showCancelButton: false,
            allowOutsieClick: false,
            width: 500,
          });
          return;
        }

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Agregado incluído com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push("/aggregates");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const response = await this.minamelServices
          .SupplierServiceV2()
          .updateAggregate(this.id, this.register);

        if (response.message) {
          this.$swal.fire({
            title: "Atenção",
            text: response.message,
            type: "info",
            showCancelButton: false,
            allowOutsieClick: false,
            width: 500,
          });
          return;
        }

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Agregado alterado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push("/aggregates");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    verifyValidAggregate() {
      const supplier = this.suppliers.find((obj) => {
        return obj.name === this.register.name;
      });

      const find = this.register.aggregates.find((obj) => {
        return obj === supplier.id;
      });

      if (find) {
        return true;
      }
      return false;
    },

    onChangeName() {},
  },
};
</script>
