/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {SupplierService}
 */
export default class SupplierService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'suppliers/';

    getSupplier () {
    	this.path = "suppliers/get";
    	return this;
    }

    createSupplier (id = null) {
    	if (id) {
    		this.path = "suppliers/update";
    	} else {
    		this.path = "suppliers/create";
    	}
    	return this;
    }
    updateSupplier () {
    	this.path = "suppliers/update";
    	return this;
    }
    deleteSupplier () {
    	this.path = "suppliers/delete";
    	return this;
    }

    getAll (params) {
    	return super.get(`/?${this.queryString(params)}`);
    }

    getAggregateById(params) {
      return super.get(`/aggregates/${(params.id)}`)
    }

    deleteAggregate(id){
      return super.delete(`/aggregates/delete/${id}`)
    }

    show (id) {
    	return super.get(`/${id}`);
    }

    store (data) {
    	return super.post('/create', data);
    }

    update (id, data) {
    	return super.put(`/edit/${id}`, data);
    }

    deleteSupplierV2 (id) {
    	return super.delete(`/delete/${id}`);
    }

    destroy (id) {
    	return super.delete(`/${id}`);
    }

    getBeekpeers () {
    	return super.get('/actions/beekpeers');
    }

    createAggregate (data) {
      console.log('data: ', data);
    	return super.post('/create-aggregate', data);
    }

    updateAggregate (id, data) {
    	return super.put(`/update-aggregate/${id}`, data);
    }

    // access() {
    //     this.path = "suppliers/access";
    //     return this;
    // }

    // group() {
    //     this.path = "suppliers/group";
    //     return this;
    // }

    // permission() {
    //     this.path = "suppliers/permission";
    //     return this;
    // }
}
